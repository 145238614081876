<template>
  <v-container fluid class="pa-1" v-if="user_info.role <= 2">
    <v-row class="py-0">
      <v-col cols="5">
        <v-file-input
          label="เลือก .xlsx"
          prepend-icon="mdi-file-excel"
          v-model="file"
          @click:clear="clear"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        ></v-file-input>
      </v-col>
      <v-col cols="5">
        <xlsx-read :file="file">
          <xlsx-sheets>
            <template #default="{ sheets }">
              <v-select
                v-model="selectedSheet"
                label="เลือก sheet งาน"
                :items="sheets"
              ></v-select>
            </template>
          </xlsx-sheets>
          <xlsx-json :sheet="selectedSheet" @parsed="pushToObject"></xlsx-json>
        </xlsx-read>
      </v-col>
      <v-col cols="2">
        <v-btn @click="doStoreNewPersonSql" class="mt-3">upload</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-resize="onResize"
          :headers="selected_column"
          :items="data"
          class="elevation-1"
          :hide-default-footer="true"
          disable-pagination
          fixed-header
          :height="windowSize.y - 180"
          dense
          :mobile-breakpoint="0"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { XlsxRead, XlsxSheets, XlsxJson } from "vue-xlsx";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};

export default {
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
  },
  data() {
    return {
      selected_column: [
        {
          text: "รหัส",
          align: "start",
          sortable: false,
          value: "jsjid",
        },
        { text: "คำนำ", value: "pname", align: "start", sortable: false },
        { text: "ชื่อ", value: "fname", align: "start", sortable: false },
        { text: "สกุล", value: "lname", align: "start", sortable: false },
        {
          text: "ปีเกิด",
          value: "year_of_birth",
          align: "start",
          sortable: false,
        },
        { text: "จังหวัด", value: "province", align: "start", sortable: false },
        {
          text: "อาชีพ",
          value: "occupation",
          align: "start",
          sortable: false,
        },
        {
          text: "หมายเลขโทรศัพท์",
          value: "phone_number",
          align: "start",
          sortable: false,
        },
        { text: "ไลน์", value: "line", align: "start", sortable: false },
        {
          text: "รู้จักมูลนิธิจาก",
          value: "know_from",
          align: "start",
          sortable: false,
        },
        {
          text: "class",
          value: "class",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง",
          value: "room",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง1",
          value: "room1",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง2",
          value: "room2",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง3",
          value: "room3",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง4",
          value: "room4",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง5",
          value: "room5",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง6",
          value: "room6",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง7",
          value: "room7",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง7.10",
          value: "room71",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง8",
          value: "room8",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง9",
          value: "room9",
          align: "start",
          sortable: false,
        },
        {
          text: "ห้อง10",
          value: "room10",
          align: "start",
          sortable: false,
        },
        {
          text: "อาจารย์ 1",
          value: "teacher1",
          align: "start",
          sortable: false,
        },
        {
          text: "อาจารย์ 2",
          value: "teacher2",
          align: "start",
          sortable: false,
        },
        { text: "สถานะ", value: "status", align: "start", sortable: false },
        { text: "โน้ต", value: "remark", align: "start", sortable: false },
      ],
      windowSize: {
        x: 0,
        y: 0,
      },
      file: null,
      file_input: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [],
      collection: [],
      data: [],
    };
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    // onChange() {
    //   this.file = this.file ? this.file : null;
    // },
    clear() {
      this.file = null;
      this.sheets = [];
      this.data = [];
      this.selectedSheet = null;
    },
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },
    pushToObject(e) {
      this.data = e;
    },

    async doStoreNewPersonSql() {
      const cloneData = [...this.data];
      for await (const dat of cloneData) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(dat)) {
          formData.append(`${key}`, `${value}`);
        }
        await axios
          .post(`${this.$store.state.apiurl}/api/people`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data.jsjid);
            let index = this.data.findIndex(
              (x) => x.jsjid == response.data.jsjid
            );
            this.data.splice(index, 1);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.errors);
            }
          });
      }
    },
  },
  computed: {
    ...mapState(["user_info"]),
  },
};
</script>
<style>
table {
  white-space: nowrap;
}
table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
</style>
